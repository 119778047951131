import * as types from "../types";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;

const API_SUBSCRIBE_LIST = types.API_BASE_URL + "subscribe/manage/list";
const API_SUBSCRIBE_NEW = types.API_BASE_URL + "subscribe/create";
const API_SUBSCRIBE_DETAIL = types.API_BASE_URL + "subscribe/manage/detail";
const API_SUBSCRIBE_UPDATE = types.API_BASE_URL + "subscribe/update";
const API_SUBSCRIBE_DELETE = types.API_BASE_URL + "subscribe/delete";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.GET_SUBSCRIBE_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SUBSCRIBE_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.SET_NEW_SUBSCRIBE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBSCRIBE_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_SUBSCRIBE_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SUBSCRIBE_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_SUBSCRIBE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBSCRIBE_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_SUBSCRIBE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBSCRIBE_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};

import * as types from "../types";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;

const API_LEVEL_LIST = types.API_BASE_URL + "level/manage/list";
const API_LEVEL_NEW = types.API_BASE_URL + "level/create";
const API_LEVEL_DETAIL = types.API_BASE_URL + "level/manage/detail";
const API_LEVEL_UPDATE = types.API_BASE_URL + "level/update";
const API_LEVEL_DELETE = types.API_BASE_URL + "level/delete";

const API_CLASS_LIST = types.API_BASE_URL + "class/manage/list";
const API_CLASS_NEW = types.API_BASE_URL + "class/create";
const API_CLASS_DETAIL = types.API_BASE_URL + "class/manage/detail";
const API_CLASS_UPDATE = types.API_BASE_URL + "class/update";
const API_CLASS_DELETE = types.API_BASE_URL + "class/delete";

const API_CURRICULUM_LIST = types.API_BASE_URL + "curriculum/manage/list";
const API_CURRICULUM_NEW = types.API_BASE_URL + "curriculum/create";
const API_CURRICULUM_DETAIL = types.API_BASE_URL + "curriculum/manage/detail";
const API_CURRICULUM_UPDATE = types.API_BASE_URL + "curriculum/update";
const API_CURRICULUM_DELETE = types.API_BASE_URL + "curriculum/delete";

const API_MAJOR_LIST = types.API_BASE_URL + "major/manage/list";
const API_MAJOR_NEW = types.API_BASE_URL + "major/create";
const API_MAJOR_DETAIL = types.API_BASE_URL + "major/manage/detail";
const API_MAJOR_UPDATE = types.API_BASE_URL + "major/update";
const API_MAJOR_DELETE = types.API_BASE_URL + "major/delete";

const API_SUBJECT_LIST = types.API_BASE_URL + "subject/manage/list";
const API_SUBJECT_NEW = types.API_BASE_URL + "subject/create";
const API_SUBJECT_DETAIL = types.API_BASE_URL + "subject/manage/detail";
const API_SUBJECT_UPDATE = types.API_BASE_URL + "subject/update";
const API_SUBJECT_DELETE = types.API_BASE_URL + "subject/delete";

const API_CHAPTER_LIST = types.API_BASE_URL + "chapter/manage/list";
const API_CHAPTER_NEW = types.API_BASE_URL + "chapter/create";
const API_CHAPTER_DETAIL = types.API_BASE_URL + "chapter/manage/detail";
const API_CHAPTER_UPDATE = types.API_BASE_URL + "chapter/update";
const API_CHAPTER_DELETE = types.API_BASE_URL + "chapter/delete";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.GET_LEVEL_LIST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_LEVEL_LIST)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_LEVEL_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_LEVEL_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_LEVEL_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_LEVEL_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_LEVEL_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CLASS_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CLASS_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_CLASS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CLASS_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CLASS_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CLASS_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_CLASS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CLASS_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_CLASS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CLASS_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CURRICULUM_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CURRICULUM_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_CURRICULUM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CURRICULUM_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CURRICULUM_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CURRICULUM_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_CURRICULUM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CURRICULUM_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_CURRICULUM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CURRICULUM_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_MAJOR_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MAJOR_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_MAJOR]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MAJOR_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_MAJOR_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MAJOR_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_MAJOR]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MAJOR_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_MAJOR]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MAJOR_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_SUBJECT_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SUBJECT_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBJECT_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_SUBJECT_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SUBJECT_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBJECT_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SUBJECT_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CHAPTER_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CHAPTER_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_CHAPTER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CHAPTER_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CHAPTER_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CHAPTER_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_CHAPTER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CHAPTER_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_CHAPTER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CHAPTER_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};

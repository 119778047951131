<template>
  <div>
    <button
      class="e-button e-button--outline-blue e-button--math-editor mt-3"
      :class="{ 'e-button--tab-active': activeTab === 'Math Editor' }"
      @click="activeTab = 'Math Editor'"
    >
      Math Editor
    </button>
    <button
      class="e-button e-button--outline-blue e-button--symbol mt-3"
      :class="{ 'e-button--tab-active': activeTab === 'Symbol' }"
      @click="activeTab = 'Symbol'"
    >
      Symbol
    </button>
    <br />
    <div class="math-editor" v-if="activeTab === 'Math Editor'">
      <div style="padding: 10px 20px 0px 20px">
        <h4>Math Symbol Preview</h4>
      </div>
      <div
        style="padding: 10px 20px 10px 20px; width: 90%; min-height: 100px; background: white; margin: 20px auto;"
      >
        <div v-katex="value"></div>
      </div>
      <div class="math-editor__top">
        <img
          :src="mi.icon"
          alt="mi.latexCode"
          v-for="(mi, i) in mathlist"
          :key="i"
          class="math-editor__icon"
          @click="handleClick(mi)"
        />
      </div>

      <div class="math-editor__bottom">
        <div class="mt-3 mb-3 ml-4 mr-4">
          <b-textarea
            id="eqn"
            rows="5"
            :value="value"
            placeholder="Type your equation in this box"
            @change="handleChange"
          ></b-textarea>
          <div class="align-center">
            <button
              class="e-button e-button--small bg-color2 color-white mt-3 mb-3" @click="sisip"
            >
              Sisipkan
            </button>
            <strong
              class="ml-3 c-pointer"
              @click="
                activeTab = 'none';
                $emit('batal');
              "
            >
              Batal
            </strong>
          </div>
        </div>
      </div>
    </div>

    <div class="math-editor" v-if="activeTab === 'Symbol'"></div>
  </div>
</template>

<script>
import { mathlist } from "../data/mathsymbol";

export default {
  data() {
    return {
      activeTab: "Math Editor",
      mathlist
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleClick(mi) {
      this.$emit("input", this.value + mi.latexCode);
    },
    handleChange(e) {
      console.log(e);
      this.$emit("input", e);
    },
    sisip() {
      this.$emit("add", this.value);
    }
  }
};
</script>

<style>
.e-button {
  height: 38.5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.78;
  text-align: center;
  border: none;
  padding: 5px 15px;
}

.e-button:focus {
  border: inherit;
}

.e-button:hover {
  background: #fbfbfb;
}

.e-button--outline-blue {
  height: 35px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #5ebbe9;
  border: 1px solid #5ebbe9;
  background: transparent;
}

.e-button--outline-blue:hover {
  color: #fff;
  background-color: #5ebbe9;
}

.e-button--math-editor {
  border-radius: 5px 0 0 5px;
}

.e-button--symbol {
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.e-button--tab-active {
  background-color: #31a8e4;
  color: #fff;
}

.e-button--tab-active:hover {
  background-color: #1b99d8;
  color: #fff;
}

.e-button--disabled {
  background-color: #e9e9e9;
  color: #c2c2c2;
}
/*=============================*/

.math-editor {
  min-height: 326px;
  border-radius: 3px;
  border: solid 1px #e9e9e9;
  background-color: #f7f8fa;
}

.math-editor__top {
  min-height: 94px;
  background-color: #eff9ff;
  padding: 5px 15px;
}

.math-editor__bottom {
}

.reply-loading {
  padding: 22px 0;
  max-width: 555px;
}

.math-editor__icon {
  padding: 5px 5px;
  margin: 2px 5px;
  cursor: pointer;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        layout: "default-layout"
      }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
      meta: {
        layout: "default-layout"
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("../views/Dashboard.vue"),
      children: [
        {
          path: "/master-data",
          name: "master-data-level",
          component: () => import("../views/dashboard/masterdata/MasterData.vue"),
          redirect: "/master-data/level",
          children: [
            {
              path: "level",
              name: "master-data-level",
              component: () => import("../views/dashboard/masterdata/MasterDataLevelList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "curriculum",
              name: "master-data-curriculum",
              component: () => import("../views/dashboard/masterdata/MasterDataCurriculumList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "class",
              name: "master-data-class",
              component: () => import("../views/dashboard/masterdata/MasterDataClassList.vue"),
              meta: { requiresAuth: true, adminAuth: true },
              props: (route) => ({ level: route.query.level, major: route.query.major })
            },
            {
              path: "major",
              name: "master-data-major",
              component: () => import("../views/dashboard/masterdata/MasterDataMajorList.vue"),
              meta: { requiresAuth: true, adminAuth: true },
              props: (route) => ({ level: route.query.level })
            }
          ]
        },
        {
          path: "/master-data",
          name: "master-data-subject",
          component: () => import("../views/dashboard/masterdata/Subject.vue"),
          redirect: "/master-data/subject",
          children: [
            {
              path: "subject",
              name: "master-data-subject",
              component: () => import("../views/dashboard/masterdata/MasterDataSubjectList.vue"),
              meta: { requiresAuth: true, adminAuth: true },
              props: (route) => ({ level: route.query.level, major: route.query.major, class: route.query.class })
            },
            {
              path: "chapter",
              name: "master-data-chapter",
              component: () => import("../views/dashboard/masterdata/MasterDataChapterList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "product",
          name: "product",
          component: () => import("../views/dashboard/Product.vue"),

          children: [
            {
              path: "list",
              name: "product-list",
              component: () =>
                import("../views/dashboard/product/ProductList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "add",
              name: "product-add",
              component: () => import("../views/dashboard/product/ProductAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "module",
          name: "module",
          component: () => import("../views/dashboard/Module.vue"),

          children: [
            {
              path: "list",
              name: "module-list",
              component: () => import("../views/dashboard/module/ModuleList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "add",
              name: "module-add",
              component: () => import("../views/dashboard/module/ModuleAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "edit/:id",
              name: "module-edit",
              component: () => import("../views/dashboard/module/ModuleEdit.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },

        {
          path: "video-bimbel",
          name: "video-bimbel",
          component: () => import("../views/dashboard/VideoBimbel.vue"),

          children: [
            {
              path: "playlist",
              name: "video-playlist",
              component: () =>
                import("../views/dashboard/video-bimbel/PlayList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "buat-playlist",
              name: "video-buat-list",
              component: () =>
                import("../views/dashboard/video-bimbel/BuatList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "edit-playlist",
              name: "video-edit-list",
              component: () =>
                import("../views/dashboard/video-bimbel/EditList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "/tryout",
          name: "tryout",
          component: () => import("../views/dashboard/tryout/TryoutMaster.vue"),
          redirect: "/tryout/level",
          children: [
            {
              path: "level",
              name: "tryout-level",
              component: () => import("../views/dashboard/tryout/TryoutLevel.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "subject",
              name: "tryout-subject",
              component: () => import("../views/dashboard/tryout/TryoutSubject.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "/tryout",
          name: "tryout",
          component: () => import("../views/dashboard/tryout/Tryout.vue"),
          redirect: "/tryout/list",
          children: [
            {
              path: "list",
              name: "tryout-list",
              component: () => import("../views/dashboard/tryout/TryoutList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "add",
              name: "tryout-add",
              component: () => import("../views/dashboard/tryout/TryoutAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "edit/:id",
              name: "tryout-edit",
              component: () => import("../views/dashboard/tryout/TryoutEdit.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "/tryout-package",
          name: "tryout-package",
          component: () => import("../views/dashboard/tryout/TryoutPackage.vue"),
          redirect: "/tryout-package/list",
          children: [
            {
              path: "list",
              name: "tryout-package-list",
              component: () => import("../views/dashboard/tryout/TryoutPackageList.vue"),
              meta: { requiresAuth: true, adminAuth: true },
              props: (route) => ({ tryout_level: route.query.tryout_level, tryout_subject: route.query.tryout_subject })
            },
            {
              path: "edit/:id",
              name: "tryout-package-edit",
              component: () => import("../views/dashboard/tryout/TryoutPackageEdit.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },
        {
          path: "/tryout-question",
          name: "tryout-question",
          component: () => import("../views/dashboard/tryout/TryoutQuestion.vue"),
          redirect: "/tryout-question/list",
          children: [
            {
              path: "list",
              name: "tryout-list",
              component: () => import("../views/dashboard/tryout/TryoutQuestionList.vue"),
              meta: { requiresAuth: true, adminAuth: true },
              props: (route) => ({ tryout_level: route.query.tryout_level, tryout_subject: route.query.tryout_subject })
            },
            {
              path: "add",
              name: "tryout-question-add",
              component: () => import("../views/dashboard/tryout/TryoutQuestionAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "edit/:id",
              name: "tryout-question-edit",
              component: () => import("../views/dashboard/tryout/TryoutQuestionEdit.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },

        {
          path: "coupon",
          name: "coupon",
          component: () => import("../views/dashboard/Coupon.vue"),

          children: [
            {
              path: "coupon-list",
              name: "coupon-list",
              component: () => import("../views/dashboard/coupon/CouponList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "coupon-add",
              name: "coupon-add",
              component: () => import("../views/dashboard/coupon/CouponAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },

        {
          path: "/package",
          name: "package",
          component: () => import("../views/dashboard/package/Package.vue"),
          redirect: "/package/list",
          children: [
            {
              path: "list",
              name: "package-list",
              component: () => import("../views/dashboard/package/PackageList.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "add",
              name: "package-add",
              component: () => import("../views/dashboard/package/PackageAdd.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            },
            {
              path: "edit/:id",
              name: "package-edit",
              component: () => import("../views/dashboard/package/PackageEdit.vue"),
              meta: { requiresAuth: true, adminAuth: true }
            }
          ]
        },

        {
          path: "/setting",
          name: "setting",
          component: () => import("../views/dashboard/setting/Setting.vue"),
          redirect: "/setting/password",
          children: [
            {
              path: "password",
              name: "password",
              component: () => import("../views/dashboard/setting/SettingPassword.vue")
            }
          ]
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  var userData = localStorage.user ? JSON.parse(localStorage.user) : 0;
  if (to.meta.requiresAuth && to.meta.adminAuth) {
    if (userData && userData.is_admin) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;

import * as types from "../types";
import axios from "axios";

const API_URL = types.API_BASE_URL + "auth/login";
const API_CHANGE_PASSWORD = types.API_BASE_URL + "auth/change-password";
const API_FORGOT_PASSWORD = types.API_BASE_URL + "auth/forgot-password";
const API_RESET_PASSWORD = types.API_BASE_URL + "auth/reset-password";

const state = {
  user: localStorage.user ? JSON.parse(localStorage.user) : null,
  token: localStorage.token || null,
  isLoggedIn: localStorage.token ? true : false
};

const getters = {
  [types.IS_LOGGED_IN]: state => {
    return state.isLoggedIn;
  },
  [types.USER]: state => {
    return state.user;
  },
  [types.TOKEN]: state => {
    return state.token;
  }
};

const mutations = {
  [types.MUTATE_LOGIN]: (state, payload) => {
    localStorage.token = payload.token;
    localStorage.user = JSON.stringify(payload.data);
    state.user = payload.data;
    state.token = payload.token;
    state.isLoggedIn = true;
    state.is_admin = payload.data.is_admin ? 1 : 0;
    state.is_student = payload.data.is_student ? 1 : 0;
    state.is_teacher = payload.data.is_teacher ? 1 : 0;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.token;
  },
  [types.MUTATE_LOGOUT]: state => {
    state.user = null;
    state.token = null;
    state.isLoggedIn = false;
    state.is_admin = null;
    state.is_student = null;
    state.is_teacher = null;
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = "";
  },
  [types.MUTATE_SET_USER]: (state, payload) => {
    state.user = payload;
    localStorage.user = JSON.stringify(payload);
  }
};

const actions = {
  [types.LOGIN]: (state, credentials) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL, credentials)
        .then(response => {
          if (response.data.success) {
            if (response.data.data.is_admin) {
              state.commit(types.MUTATE_LOGIN, response.data);
              resolve(response.data);
            } else {
              reject("Not admin account");
            }
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          state.commit(types.MUTATE_LOGOUT);
          reject(error.message);
        });
    });
  },
  [types.LOGOUT]: ({ commit }) => {
    commit(types.MUTATE_LOGOUT);
  },
  [types.CHANGE_PASSWORD]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CHANGE_PASSWORD, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          state.commit(types.MUTATE_LOGOUT);
          reject(error.message);
        });
    });
  },
  [types.FORGOT_PASSWORD]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_FORGOT_PASSWORD, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          state.commit(types.MUTATE_LOGOUT);
          reject(error.message);
        });
    });
  },
  [types.RESET_PASSWORD]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_RESET_PASSWORD, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          state.commit(types.MUTATE_LOGOUT);
          reject(error.message);
        });
    });
  },
  [types.SET_USER_INFO]: (state, payload) => {
    state.commit(types.MUTATE_SET_USER, payload);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};

<template>
  <div
    class="sidebar"
    :class="{
      'sidebar--collapsed': collapsed,
      'sidebar--expanded': !collapsed
    }"
    ref="sidebar"
  >
    <template v-for="(item, i) in menuItems">
      <div
        :key="i"
        @mouseover="handleMouseHover($event, i)"
        @click="
          showingHover = false;
          handleLinkClick(i, item);
        "
        :class="{ 'sub-item': item.subMenus }"
      >
        <router-link
          class="sidebar__item"
          :class="{
            'sidebar__item--active':
              activeItemIndex === i || (hoverIndex === i && showingHover)
          }"
          :to="item.to"
          v-if="!item.subMenus"
        >
          <fa-icon :icon="item.icon" class="my-icon" />

          <div class="sidebar-item-text">
            {{ item.name }}
          </div>
        </router-link>

        <router-link
          class="sidebar__item"
          :class="{
            'sidebar__item--active':
              activeItemIndex === i || (hoverIndex === i && showingHover)
          }"
          v-else
          :to="item.to"
          v-on:click.native="toggleSubmenu(item, i)"
        >
          <fa-icon :icon="item.icon" class="my-icon" />

          <div class="sidebar-item-text space-between align-center">
            <span>
              {{ item.name }}
            </span>
            <div
              :class="{
                'arrow-down': !item.expanded,
                'arrow-up': item.expanded
              }"
            ></div>
          </div>
        </router-link>

        <template v-for="(itm, ii) in item.subMenus">
          <transition name="height" :key="ii">
            <router-link
              :key="ii"
              class="sidebar__item sidebar__item--sub"
              :to="itm.to"
              v-if="item.expanded && !collapsed"
              v-on:click.native="handleSubItemClick"
            >
              <fa-icon :icon="itm.icon" class="my-icon" />

              <div class="sidebar-item-text">
                {{ itm.name }}
              </div>
            </router-link>
          </transition>
        </template>
      </div>
    </template>

    <div
      class="test-hover"
      ref="testHover"
      @mouseleave="hideMenu"
      :style="{ visibility: showingHover ? 'visible' : 'hidden' }"
    >
      <div
        :key="i"
        v-for="(item, i) in menuItems"
        @click="showingHover = false"
      >
        <router-link
          class="sidebar__item"
          :class="{ 'sidebar__item--active': hoverIndex === i }"
          :to="item.to"
          v-if="hoverIndex === i"
        >
          <!-- <div class="sidebar-item-text--hover">
                            {{ item.name }}
                        </div> -->

          <div class="sidebar-item-text--hover space-between align-center">
            <span>
              {{ item.name }}
            </span>
            <div class="arrow-down" v-if="item.subMenus"></div>
          </div>
        </router-link>

        <template v-for="(itm, ii) in item.subMenus">
          <router-link
            :key="ii"
            class="sidebar__item sidebar__item--sub collapse-sub"
            :to="itm.to"
            v-if="hoverIndex === i"
            v-on:click.native="activeItemIndex = i"
          >
            <fa-icon :icon="itm.icon" class="my-icon" />

            <div class="sidebar-item-text--collapsed">
              {{ itm.name }}
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      menuItems: [
        { name: "Beranda", icon: "home", to: "/dashboard" },
        {
          name: "Master Data",
          icon: "database",
          to: "/master-data/level",
          expanded: false,
          subMenus: [
            {
              name: "Jenjang",
              icon: "database",
              to: "/master-data/level"
            },
            {
              name: "Mata Pelajaran",
              icon: "list-ul",
              to: "/master-data/subject"
            }
          ]
        },
        {
          name: "Module",
          icon: "cube",
          to: "/dashboard/module/list",
          expanded: false,
          subMenus: [
            {
              name: "Daftar Modul Belajar",
              icon: "list-ul",
              to: "/dashboard/module/list"
            },
            {
              name: "Buat Modul Belajar",
              icon: "plus-circle",
              to: "/dashboard/module/add"
            },
            // {
            //   name: "Edit Modul Belajar",
            //   icon: "pencil-alt",
            //   to: "/dashboard/module/edit"
            // }
          ]
        },
        // {
        //   name: "Video Vimbel",
        //   icon: "play-circle",
        //   to: "/dashboard/video-bimbel/playlist"
        // },
        {
          name: "Tryout Online",
          icon: "book-open",
          to: "/tryout/list",
          expanded: false,
          subMenus: [
            {
              name: "Tryout",
              icon: "list-ul",
              to: "/tryout/list"
            },
            {
              name: "Paket Soal",
              icon: "list-ul",
              to: "/tryout-package/list"
            },
            {
              name: "Bank Soal",
              icon: "database",
              to: "/tryout-question/list"
            },
            {
              name: "Master Data",
              icon: "database",
              to: "/tryout/level"
            }
          ]
        },

        // {
        //   name: "Product",
        //   icon: "cube",
        //   to: "/dashboard/product/list",
        //   expanded: false,
        //   subMenus: [
        //     {
        //       name: "Product List",
        //       icon: "list-ul",
        //       to: "/dashboard/product/list"
        //     },
        //     {
        //       name: "Add Product",
        //       icon: "plus-circle",
        //       to: "/dashboard/product/add"
        //     }
        //   ]
        // },
        // { name: "Kategory", icon: "th-large", to: "/dashboard/category" },
        // { name: "Order", icon: "shopping-cart", to: "/dashboard/order" },
        {
          name: "Kupon",
          icon: "ticket-alt",
          to: "/dashboard/coupon/coupon-list"
        },
        {
          name: "Package",
          icon: "list-alt",
          to: "/package/list"
        },
        {
          name: "Customer",
          icon: "crown",
          to: "#"
        },
        {
          name: "Alfath SKILL",
          icon: "clipboard-list",
          to: "#"
        },
        {
          name: "Mobile APP",
          icon: "mobile-alt",
          to: "#"
        },
        { name: "Setting", icon: "cog", to: "/setting" },
        { name: "Sign Out", icon: "sign-out-alt", to: "/logout" }
      ],
      activeItemIndex: 0,
      hoverIndex: -1,
      showingHover: false
    };
  },

  methods: {
    ...mapActions({
      logout: types.LOGOUT
    }),
    handleLinkClick(i, item) {
      if (item.to === "/logout") {
        this.logout();
      }
      this.activeItemIndex = i;
      if (window.innerWidth < 900 && !item.subMenus) {
        this.$emit("onCollapsed");
      }
    },

    handleMouseHover(e, i) {
      this.hoverIndex = i;
      if (
        this.$refs.sidebar.clientWidth < 155 &&
        e.target.classList.contains("sidebar__item")
      ) {
        let topHeight = e.target.offsetTop + 77 - this.$refs.sidebar.scrollTop;
        // this.$refs.testHover.style.visibility = 'visible' ;
        this.$refs.testHover.style.top = topHeight + "px";
        this.showingHover = true;
      }
    },

    hideMenu() {
      // this.$refs.testHover.style.visibility = 'hidden';
      this.showingHover = false;
    },

    toggleSubmenu(item, i) {
      console.log(item);
      item.expanded = !item.expanded;

      // console.log(this.menuItems[i].expanded);
      // this.menuItems[i].expanded = true;
      // console.log(this.menuItems[i].expanded);

      this.$forceUpdate();
    },

    handleSubItemClick() {
      if (window.innerWidth < 900) {
        this.$emit("onCollapsed");
      }
    }
  },

  props: {
    collapsed: {
      default: false
    }
  }
};
</script>

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faPlayCircle,
  faDownload,
  faBookOpen,
  faUserFriends,
  faGraduationCap,
  faMobileAlt,
  faCog,
  faSignOutAlt,
  faBook,
  faEdit,
  faUpload,
  faDatabase,
  faPlusCircle,
  faAtom,
  faArrowLeft,
  faSearch,
  faLock,
  faListAlt,
  faClock,
  faArrowRight,
  faCrown,
  faPencilAlt,
  faTrashAlt,
  faMinusCircle,
  faHome,
  faCube,
  faListUl,
  faThLarge,
  faShoppingCart,
  faPercentage,
  faTimes,
  faSortDown,
  faCopy,
  faPlus,
  faCircle,
  faSave,
  faSort,
  faFilePdf,
  faThList,
  faTicketAlt,
  faClipboardList
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPlayCircle,
  faDownload,
  faBookOpen,
  faUserFriends,
  faGraduationCap,
  faMobileAlt,
  faCog,
  faSignOutAlt,
  faBook,
  faEdit,
  faUpload,
  faDatabase,
  faPlusCircle,
  faAtom,
  faArrowLeft,
  faSearch,
  faLock,
  faListAlt,
  faClock,
  faArrowRight,
  faCrown,
  faPencilAlt,
  faTrashAlt,
  faMinusCircle,
  faHome,
  faCube,
  faListUl,
  faPlusCircle,
  faThLarge,
  faShoppingCart,
  faPercentage,
  faTimes,
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedinIn,
  faSortDown,
  faCopy,
  faPlus,
  faCircle,
  faSave,
  faSort,
  faFilePdf,
  faThList,
  faTicketAlt,
  faClipboardList
);

export default FontAwesomeIcon;

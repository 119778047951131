import * as types from "../types";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;

const API_TRYOUT_LEVEL_LIST = types.API_BASE_URL + "tryout-level/list";
const API_TRYOUT_LEVEL_NEW = types.API_BASE_URL + "tryout-level/create";
const API_TRYOUT_LEVEL_DETAIL = types.API_BASE_URL + "tryout-level/detail";
const API_TRYOUT_LEVEL_UPDATE = types.API_BASE_URL + "tryout-level/update";
const API_TRYOUT_LEVEL_DELETE = types.API_BASE_URL + "tryout-level/delete";

const API_TRYOUT_SUBJECT_LIST = types.API_BASE_URL + "tryout-subject/manage/list";
const API_TRYOUT_SUBJECT_NEW = types.API_BASE_URL + "tryout-subject/create";
const API_TRYOUT_SUBJECT_DETAIL = types.API_BASE_URL + "tryout-subject/detail";
const API_TRYOUT_SUBJECT_UPDATE = types.API_BASE_URL + "tryout-subject/update";
const API_TRYOUT_SUBJECT_DELETE = types.API_BASE_URL + "tryout-subject/delete";

const API_TRYOUT_LIST = types.API_BASE_URL + "tryout/manage/list";
const API_TRYOUT_DETAIL = types.API_BASE_URL + "tryout/manage/detail";
const API_TRYOUT_NEW = types.API_BASE_URL + "tryout/create";
const API_TRYOUT_UPDATE = types.API_BASE_URL + "tryout/update";
const API_TRYOUT_DELETE = types.API_BASE_URL + "tryout/delete"
const API_TRYOUT_PACKAGE = types.API_BASE_URL + "tryout/add-package";
const API_TRYOUT_PACKAGE_REMOVE = types.API_BASE_URL + "tryout/remove-package";
const API_TRYOUT_PACKAGE_ORDER= types.API_BASE_URL + "tryout/order";

const API_TRYOUT_PACKAGE_LIST = types.API_BASE_URL + "tryout-package/manage/list";
const API_TRYOUT_PACKAGE_DETAIL = types.API_BASE_URL + "tryout-package/detail";
const API_TRYOUT_PACKAGE_NEW = types.API_BASE_URL + "tryout-package/create";
const API_TRYOUT_PACKAGE_UPDATE = types.API_BASE_URL + "tryout-package/update";
const API_TRYOUT_PACKAGE_DELETE = types.API_BASE_URL + "tryout-package/delete";
const API_TRYOUT_PACKAGE_QUESTION = types.API_BASE_URL + "tryout-package/add-question";
const API_TRYOUT_PACKAGE_QUESTION_REMOVE = types.API_BASE_URL + "tryout-package/remove-question";
const API_TRYOUT_QUESTION_ORDER = types.API_BASE_URL + "tryout-package/order";

const API_TRYOUT_QUESTION_LIST = types.API_BASE_URL + "tryout-question/manage/list";
const API_TRYOUT_QUESTION_DETAIL = types.API_BASE_URL + "tryout-question/detail";
const API_TRYOUT_QUESTION_NEW = types.API_BASE_URL + "tryout-question/create";
const API_TRYOUT_QUESTION_UPDATE = types.API_BASE_URL + "tryout-question/update";
const API_TRYOUT_QUESTION_DELETE = types.API_BASE_URL + "tryout-question/delete"

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.GET_TRYOUT_LEVEL_LIST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_LEVEL_LIST)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_TRYOUT_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_LEVEL_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_LEVEL_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_LEVEL_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_LEVEL_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_TRYOUT_LEVEL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_LEVEL_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_SUBJECT_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_SUBJECT_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_TRYOUT_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_SUBJECT_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_SUBJECT_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_SUBJECT_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data[0]);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_SUBJECT_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_TRYOUT_SUBJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_SUBJECT_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_TRYOUT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_TRYOUT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_TRYOUT_PACKAGE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.REMOVE_TRYOUT_PACKAGE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_REMOVE, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_PACKAGE_ORDER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_ORDER, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_PACKAGE_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_PACKAGE_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_PACKAGE_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_PACKAGE_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_TRYOUT_PACKAGE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_NEW, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_PACKAGE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_UPDATE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_TRYOUT_PACKAGE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_TRYOUT_PACKAGE_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_QUESTION, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.REMOVE_TRYOUT_PACKAGE_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_PACKAGE_QUESTION_REMOVE, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_QUESTION_ORDER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_QUESTION_ORDER, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_QUESTION_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_QUESTION_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_TRYOUT_QUESTION_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_TRYOUT_QUESTION_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_TRYOUT_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_QUESTION_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_TRYOUT_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_QUESTION_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_TRYOUT_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_TRYOUT_QUESTION_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  }

};

export default {
  state,
  mutations,
  actions,
  getters
};

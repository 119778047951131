export const mathlist = [
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "/img/math-editors/math-editor1.svg"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "/img/math-editors/math-editor2.svg"
  },
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "/img/math-editors/math-editor3.svg"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "/img/math-editors/math-editor4.svg"
  },
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "/img/math-editors/math-editor5.svg"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "/img/math-editors/math-editor6.svg"
  },
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "/img/math-editors/math-editor5.svg"
  }
];

export const symbolList = [
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "URL"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "URL"
  },
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "URL"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "URL"
  },
  {
    value: "",
    latexCode: "\\sqrt{x}",
    icon: "URL"
  },
  {
    value: "",
    latexCode: "x^{2}",
    icon: "URL"
  }
];

// Import vue components
import MyHeader from "./MyHeader.vue";
import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";
import MyFooter from "./MyFooter.vue";
import MyButton from "./MyButton.vue";
import ModalCross from "./ModalCross.vue";
import MathEditor from "./MathEditor.vue";
import { VueEditor } from "vue2-editor";

// font-awesome
import FontAwesomeIcon from "./fontawesome";

export default {
  install(Vue /*, options */) {
    Vue.component("my-header", MyHeader);
    Vue.component("topbar", Topbar);
    Vue.component("sidebar", Sidebar);
    Vue.component("my-footer", MyFooter);
    Vue.component("my-button", MyButton);
    Vue.component("modal-cross", ModalCross);
    Vue.component("math-editor", MathEditor);

    // font-awesome
    Vue.component("fa-icon", FontAwesomeIcon);

    // Rich text editor
    Vue.component("vue-editor", VueEditor);
  }
};

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import auth from "./modules/auth";
import masterdata from "./modules/masterdata";
import module from "./modules/module";
import tryout from "./modules/tryout";
import subscribe from "./modules/subscribe";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    pageTitle: ""
  },
  getters,
  mutations,
  actions,
  modules: {
    auth,
    masterdata,
    module,
    tryout,
    subscribe
  },
  plugins: [createPersistedState()]
});

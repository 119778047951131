import * as types from "../types";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;

const API_SECTION_LIST = types.API_BASE_URL + "section/manage/list";
const API_SECTION_NEW = types.API_BASE_URL + "section/create";
const API_SECTION_DETAIL = types.API_BASE_URL + "section/manage/detail";
const API_SECTION_UPDATE = types.API_BASE_URL + "section/update";
const API_SECTION_DELETE = types.API_BASE_URL + "section/delete";

const API_CONTENT_LIST = types.API_BASE_URL + "content/manage/list";
const API_CONTENT_NEW = types.API_BASE_URL + "content/create";
const API_CONTENT_DETAIL = types.API_BASE_URL + "content/manage/detail";
const API_CONTENT_UPDATE = types.API_BASE_URL + "content/update";
const API_CONTENT_DELETE = types.API_BASE_URL + "content/delete";
const API_CONTENT_PUBLISH = types.API_BASE_URL + "content/publish";
const API_CONTENT_ORDER = types.API_BASE_URL + "content/order";

const API_QUIZ_LIST = types.API_BASE_URL + "quiz/manage/list";
const API_QUIZ_NEW = types.API_BASE_URL + "quiz/create";
const API_QUIZ_DETAIL = types.API_BASE_URL + "quiz/manage/detail";
const API_QUIZ_UPDATE = types.API_BASE_URL + "quiz/update";
const API_QUIZ_DELETE = types.API_BASE_URL + "quiz/delete";
const API_QUIZ_PUBLISH = types.API_BASE_URL + "quiz/publish";

const API_QUESTION_LIST = types.API_BASE_URL + "question/list";
const API_QUESTION_NEW = types.API_BASE_URL + "question/create";
const API_QUESTION_DETAIL = types.API_BASE_URL + "question/detail";
const API_QUESTION_UPDATE = types.API_BASE_URL + "question/update";
const API_QUESTION_DELETE = types.API_BASE_URL + "question/delete";
const API_QUESTION_PUBLISH = types.API_BASE_URL + "question/publish";
const API_QUESTION_ORDER = types.API_BASE_URL + "quiz/order";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.GET_SECTION_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SECTION_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_SECTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SECTION_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_SECTION_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SECTION_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_SECTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SECTION_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_SECTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SECTION_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_CONTENT_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CONTENT_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_CONTENT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CONTENT_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_CONTENT_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_CONTENT_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_CONTENT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CONTENT_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_CONTENT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CONTENT_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.PUBLISH_CONTENT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CONTENT_PUBLISH, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_ORDER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_CONTENT_ORDER, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_QUIZ_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_QUIZ_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_QUIZ]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUIZ_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_QUIZ_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_QUIZ_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_QUIZ]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUIZ_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_QUIZ]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUIZ_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.PUBLISH_QUIZ]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUIZ_PUBLISH, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_QUESTION_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_QUESTION_LIST, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_NEW_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUESTION_NEW, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.GET_QUESTION_DETAIL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_QUESTION_DETAIL + "/" + payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.UPDATE_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUESTION_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.DELETE_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUESTION_DELETE, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.PUBLISH_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUESTION_PUBLISH, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },

  [types.SET_ORDER_QUESTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_QUESTION_ORDER, payload)
        .then(response => {
          if (response.data.status == 200) {
            resolve(response.data.data);
          } else {
            reject(response.data.messages);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};

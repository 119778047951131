/*=============== Base URL ============*/
export const API_BASE_URL = "https://api.alfathbelajar.com/";

/*================ Getters ============*/
// General / Shared
export const PAGE_TITLE = "pageTitle";
export const SIDEBAR_NAME = "sidebarName";
export const SIDEBAR_COLLAPSED = "sidebarCollapsed";

// auth module
export const IS_LOGGED_IN = "isLoggedIn";
export const USER = "user";
export const TOKEN = "token";

/*============= Mutations ===============*/
// General / Shared
export const MUTATE_SET_PAGE_TITLE = "mutateSetPageTitle";
export const MUTATE_SIDEBAR_NAME = "mutateSidebarName";
export const MUTATE_SET_SIDEBAR_COLLAPSED = "mutateSidebarCollapsed";

// auth module
export const MUTATE_SET_USER = "mutateSetUserInfo";
export const MUTATE_LOGOUT = "mutateLogout";
export const MUTATE_LOGIN = "mutateLogin";

/*=============== Actions =================*/
// General / Shared
export const SET_PAGE_TITLE = "setPageTitle";
export const SET_SIDEBAR_NAME = "setSidebarName";
export const SET_SIDEBAR_COLLAPSED = "setSidebarCollapsed";

// auth module
export const SET_USER_INFO = "setLoginStatus";
export const LOGOUT = "logout";
export const LOGIN = "login";
export const CHANGE_PASSWORD = "changePassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";

// masterdata module
export const GET_LEVEL_LIST = "getLevelList";
export const SET_NEW_LEVEL = "setNewLevel";
export const GET_LEVEL_DETAIL = "getLevelDetail";
export const UPDATE_LEVEL = "updateLevel";
export const DELETE_LEVEL = "deleteLevel";

export const GET_CLASS_LIST = "getClassList";
export const SET_NEW_CLASS = "setNewClass";
export const GET_CLASS_DETAIL = "getClassDetail";
export const UPDATE_CLASS = "updateClass";
export const DELETE_CLASS = "deleteClass";

export const GET_CURRICULUM_LIST = "getCurriculumList";
export const SET_NEW_CURRICULUM = "setNewCurriculum";
export const GET_CURRICULUM_DETAIL = "getCurriculumDetail";
export const UPDATE_CURRICULUM = "updateCurriculum";
export const DELETE_CURRICULUM = "deleteCurriculum";

export const GET_MAJOR_LIST = "getMajorList";
export const SET_NEW_MAJOR = "setNewMajor";
export const GET_MAJOR_DETAIL = "getMajorDetail";
export const UPDATE_MAJOR = "updateMajor";
export const DELETE_MAJOR = "deleteMajor";

export const GET_SUBJECT_LIST = "getSubjectList";
export const SET_NEW_SUBJECT = "setNewSubject";
export const GET_SUBJECT_DETAIL = "getSubjectDetail";
export const UPDATE_SUBJECT = "updateSubject";
export const DELETE_SUBJECT = "deleteSubject";

export const GET_CHAPTER_LIST = "getChapterList";
export const SET_NEW_CHAPTER = "setNewChapter";
export const GET_CHAPTER_DETAIL = "getChapterDetail";
export const UPDATE_CHAPTER = "updateChapter";
export const DELETE_CHAPTER = "deleteChapter";

// module
export const GET_SECTION_LIST = "getSectionList";
export const SET_NEW_SECTION = "setNewSection";
export const GET_SECTION_DETAIL = "getSectionDetail";
export const UPDATE_SECTION = "updateSection";
export const DELETE_SECTION = "deleteSection";

//content
export const GET_CONTENT_LIST = "getContentList";
export const SET_NEW_CONTENT = "setNewContent";
export const GET_CONTENT_DETAIL = "getContentDetail";
export const UPDATE_CONTENT = "updateContent";
export const DELETE_CONTENT = "deleteContent";
export const PUBLISH_CONTENT = "publishContent";
export const SET_ORDER = "setOrder";

//quiz
export const GET_QUIZ_LIST = "getQuizList";
export const SET_NEW_QUIZ = "setNewQuiz";
export const GET_QUIZ_DETAIL = "getQuizDetail";
export const UPDATE_QUIZ = "updateQuiz";
export const DELETE_QUIZ = "deleteQuiz";
export const PUBLISH_QUIZ = "publishQuiz";

export const GET_QUESTION_LIST = "getQuestionList";
export const SET_NEW_QUESTION = "setNewQuestion";
export const GET_QUESTION_DETAIL = "getQuestionDetail";
export const UPDATE_QUESTION = "updateQuestion";
export const DELETE_QUESTION = "deleteQuestion";
export const PUBLISH_QUESTION = "publishQuestion";
export const SET_ORDER_QUESTION = "setOrderQuestion";

export const GET_TRYOUT_LEVEL_LIST = "getTryoutLevelList";
export const SET_NEW_TRYOUT_LEVEL = "setNewTryoutLevel";
export const GET_TRYOUT_LEVEL_DETAIL = "getTryoutLevelDetail";
export const UPDATE_TRYOUT_LEVEL = "updateTryoutLevel";
export const DELETE_TRYOUT_LEVEL = "deleteTryoutLevel";

export const GET_TRYOUT_SUBJECT_LIST = "getTryoutSubjectList";
export const SET_NEW_TRYOUT_SUBJECT = "setNewTryoutSubject";
export const GET_TRYOUT_SUBJECT_DETAIL = "getTryoutSubjectDetail";
export const UPDATE_TRYOUT_SUBJECT = "updateTryoutSubject";
export const DELETE_TRYOUT_SUBJECT = "deleteTryoutSubject";

export const GET_TRYOUT_LIST = "getTryoutList";
export const GET_TRYOUT_DETAIL = "getTryoutDetail";
export const SET_NEW_TRYOUT = "setNewTryout";
export const UPDATE_TRYOUT = "updateTryout";
export const DELETE_TRYOUT = "deleteTryout";
export const SET_TRYOUT_PACKAGE = "setTryoutPackage";
export const REMOVE_TRYOUT_PACKAGE = "removeTryoutPackage";
export const UPDATE_TRYOUT_PACKAGE_ORDER = "updateTryoutPackageOrder";

export const GET_TRYOUT_PACKAGE_LIST = "getTryoutPackageList";
export const GET_TRYOUT_PACKAGE_DETAIL = "getTryoutPackageDetail";
export const SET_NEW_TRYOUT_PACKAGE = "setNewTryoutPackage";
export const UPDATE_TRYOUT_PACKAGE = "updateTryoutPackage";
export const DELETE_TRYOUT_PACKAGE = "deleteTryoutPackage";
export const SET_TRYOUT_PACKAGE_QUESTION = "setTryoutPackageQuestion";
export const REMOVE_TRYOUT_PACKAGE_QUESTION = "removeTryoutPackageQuestion";
export const UPDATE_TRYOUT_QUESTION_ORDER = "updateTryoutQuestionOrder";

export const GET_TRYOUT_QUESTION_LIST = "getTryoutQuestionList";
export const SET_NEW_TRYOUT_QUESTION = "setNewTryoutQuestion";
export const GET_TRYOUT_QUESTION_DETAIL = "getTryoutQuestionDetail";
export const UPDATE_TRYOUT_QUESTION = "updateTryoutQuestion";
export const DELETE_TRYOUT_QUESTION = "deleteTryoutQuestion";

export const GET_SUBSCRIBE_LIST= "GET_SUBSCRIBE_LIST";
export const PUBLISH_SUBSCRIBE = "PUBLISH_SUBSCRIBE";
export const SET_NEW_SUBSCRIBE = "SET_NEW_SUBSCRIBE";
export const GET_SUBSCRIBE_DETAIL = "GET_SUBSCRIBE_DETAIL";
export const UPDATE_SUBSCRIBE = "UPDATE_SUBSCRIBE";
export const DELETE_SUBSCRIBE = "DELETE_SUBSCRIBE";
